import { initializeApp } from "firebase/app";
import { ProductItemTypes, UserDataTypes, UserProductTypes } from "../models/types"

import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    Auth,
    onAuthStateChanged,
    EmailAuthProvider,
    reauthenticateWithCredential
} from "firebase/auth";
import {
    getFirestore,
    collection,
    addDoc,
    doc,
    getDoc,
    setDoc, query, where, getDocs, Firestore, Timestamp, updateDoc, deleteDoc,
    arrayUnion
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC2bQ9IebZ1KuYEBjrnL5zNdDxeOrZWbFg",
    authDomain: "codeus-production.firebaseapp.com",
    projectId: "codeus-production",
    storageBucket: "codeus-production.appspot.com",
    messagingSenderId: "635116471265",
    appId: "1:635116471265:web:30ea23fc097d57f75a48a0",
    measurementId: "G-BRQ3QHV6LR"
};

const app = initializeApp(firebaseConfig);
const auth: Auth = getAuth(app);
const db: Firestore = getFirestore(app);

//const [updateUserState] = UserLoginStore((state) => [state.UpdateUserLoginState]);

// sign-in
const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        await getUserData(); // Fetch the user data
        migrateOldAccounts(); // Fix older accounts that use 'UID' instead of 'ID'
    } catch (err: any) {
        throw err.code
    }
};

const migrateUsers = async () => {
    const usersCollection = collection(db, "users");

    try {
        // Step 1: Get all user documents
        const querySnapshot = await getDocs(usersCollection);

        querySnapshot.forEach(async (docSnapshot) => {
            const oldDocId = docSnapshot.id;
            const userData = docSnapshot.data();

            // Step 2: Check if the old document ID matches the UID in the "id" field
            if (userData.id && oldDocId !== userData.id) {
                const newDocId = userData.id; // This is the correct UID from Firebase Authentication

                // Step 3: Create a new document using UID as document ID
                const newDocRef = doc(db, "users", newDocId);
                await setDoc(newDocRef, userData, { merge: true }); // Merge to ensure no data loss

                // Step 4: Delete the old document
                const oldDocRef = doc(db, "users", oldDocId);
                await deleteDoc(oldDocRef);

                console.log(`Migrated user with old ID: ${oldDocId} to new ID: ${newDocId}`);
            }
        });

        console.log("Migration completed successfully!");
    } catch (error) {
        console.error("Error during migration:", error);
    }
};

// save user data
const saveUserData = async (userData: UserDataTypes) => {
    const user = auth.currentUser;
    
    try {
        if (user) {
            const docRef = doc(db, "users", user.uid); // Using user.uid as the Firestore document ID
            await setDoc(docRef, userData, { merge: true }); // Merge option keeps existing fields and updates new ones
            console.log("User data saved successfully!");
        } else {
            console.log("No user is signed in.");
        }
    } catch (error) {
        console.error("Error saving user data:", error);
    }
};

// sign-up
const registerWithEmailAndPassword = async (name: string, company: string, email: string, password: string) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        /*
        const actionCodeSettings = {
            url: 'https://www.artomeinstaller.com/?email={$user@example.com}',
            iOS: {
                bundleId: 'com.artome-installer.ios'
            },
            android: {
                packageName: 'com.artome-installer.android',
                installApp: true,
                minimumVersion: '12'
            }
        }
        await sendEmailVerification(user, actionCodeSettings);
        */
        await saveUserData(
            {
                id: user.uid,
                name,
                company,
                email,
                register_method: "artomeinstaller.com"
            }
        );
    } catch (err: any) {
        throw err.code
    }
};

// send email for verification
const emailVerification = async () => {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const actionCodeSettings = {
                url: 'https://www.artomeinstaller.com/?email={$user@example.com}',
                iOS: {
                    bundleId: 'com.artome-installer.ios'
                },
                android: {
                    packageName: 'com.artome-installer.android',
                    installApp: true,
                    minimumVersion: '12'
                }
            }
            try {
                await sendEmailVerification(user, actionCodeSettings)
                    .then(() => {
                        // Email verification sent
                        alert("check your email");
                    });
            } catch (err: any) {
                throw err.code;
            }
        } else {
            // User is signed out
            // ...
        }
    });
};

// send user password reset
const sendPasswordReset = async (email: string): Promise<void> => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log("Check your email for the password reset link!");
    } catch (error) {
        const errorCode = (error as { code: string }).code;
        let errorMessage: string;

        switch (errorCode) {
            case 'auth/user-not-found':
                errorMessage = 'No user found with this email address.';
                break;
            case 'auth/invalid-email':
                errorMessage = 'The email address is not valid.';
                break;
            case 'auth/too-many-requests':
                errorMessage = 'Too many requests. Please try again later.';
                break;
            default:
                errorMessage = 'An error occurred. Please try again.';
                break;
        }

        console.error("Error sending password reset email:", errorMessage);
    }
};

// log-out user
const logout = async () => {
    await signOut(auth);
};

// get user data
const getUserData = async () => {
    const user = auth.currentUser;
    if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        console.log("User data fetched successfully!");

        if (docSnap.exists()) {
            const userData = docSnap.data() as UserDataTypes;

            // Check if the user is marked for deletion
            if (userData.markedForDeletion) {
                console.warn("User account is marked for deletion.");
                await logout();
                return null;
            }

            return userData;
        } else {
            console.log("No user data found!");
            return null;
        }
    } else {
        console.log("No user is signed in.");
        return null;
    }
};

// get user products
const getUserProductsData = async () => {
    const user = auth.currentUser;

    if (user) {
        const q = query(collection(db, "web_installer"), where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const products: UserProductTypes[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const product: UserProductTypes = {
                ...data,
                setup_date: (data.setup_date as Timestamp).toDate().toLocaleString(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }) // Convert Timestamp to Date and then to string
            } as UserProductTypes;
            products.push(product);
        });
        return products;
    } else {
        throw new Error("No user is currently signed in.");
    }
};

// get user product item
const getUserProductItemData = async (artomeSerialNo: string) => {
    const user = auth.currentUser;

    if (user) {
        const q = query(collection(db, "web_installer"), where("uid", "==", user.uid), where("artome_serial_no", "==", artomeSerialNo));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            const product: ProductItemTypes = {
                ...data,
                setup_date: (data.setup_date as Timestamp).toDate().toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }) // Convert Timestamp to Date and then to string
            } as ProductItemTypes;
            return product;
        } else {
            throw new Error("No product found for the current user with the given artome_serial_no.");
        }
    } else {
        throw new Error("No user is currently signed in.");
    }
};

// create & update products registration records
const registrationRecords = async (epsonSerNo: string, artomeSerNo: string, product: string, owner: string) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const docToUpdate = querySnapshot.docs[0];
                    const docData = docToUpdate.data();
                    const previousOwners = docData.previous_owners || [];
                    if (docData.product_owner) {
                        previousOwners.push({
                            owner: docData.product_owner,
                            date: docData.re_setup_date || docData.setup_date,
                        });
                    }

                    const updatedData = {
                        artome_product: product.toUpperCase(),
                        product_owner: owner,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        previous_owners: previousOwners,
                        re_setup_date: new Date(),
                        uid: user.uid
                    };

                    console.log("Data to be saved:", updatedData);

                    await updateDoc(doc(db, "web_installer", docToUpdate.id), updatedData);
                } else {
                    const newData = {
                        artome_product: product.toUpperCase(),
                        product_owner: owner,
                        artome_serial_no: artomeSerNo.toUpperCase(),
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        previous_owners: [],
                        setup_date: new Date(),
                        uid: user.uid
                    };

                    console.log("Data to be added:", newData);

                    await addDoc(collection(db, "web_installer"), newData);
                }
            } else {
                // User is signed out
                // Handle sign-out scenario if necessary
                console.warn("User is signed out.");
            }
        });
    } catch (err) {
        console.error(err);
    }
};


// create & update the S1 provisioning records
const s1ProvisioningRecords = async (epsonSerNo: string, artomeSerNo: string, product: string, productSetupCompleted: boolean, SoftwareSetupCompleted: boolean) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no already exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no already exists, update it
                    const docToUpdate = querySnapshot.docs[0];
                    await updateDoc(doc(db, "web_installer", docToUpdate.id), {
                        artome_product: product,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        re_setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                } else {
                    // No document with the same artome_serial_no exists, proceed to add
                    await addDoc(collection(db, "web_installer"), {
                        artome_product: product,
                        artome_serial_no: artomeSerNo,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                }
            } else {
                // User is signed out
                // ...
            }
        });
    } catch (err: any) {
        console.error(err);
    }
};

// create & update the M10 provisioning records
const m10ProvisioningRecords = async (epsonSerNo: string, artomeSerNo: string, product: string, productSetupCompleted: boolean, SoftwareSetupCompleted: boolean) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no already exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no already exists, update it
                    const docToUpdate = querySnapshot.docs[0];
                    await updateDoc(doc(db, "web_installer", docToUpdate.id), {
                        artome_product: product,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        re_setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                } else {
                    // No document with the same artome_serial_no exists, proceed to add
                    await addDoc(collection(db, "web_installer"), {
                        artome_product: product,
                        artome_serial_no: artomeSerNo,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                }
            } else {
                // User is signed out
                // ...
            }
        });
    } catch (err: any) {
        console.error(err);
    }
};

// create & update the X20 provisioning records
const x20ProvisioningRecords = async (epsonSerNo: string, artomeSerNo: string, lens: string, product: string, lensSetupCompleted: boolean, productSetupCompleted: boolean, SoftwareSetupCompleted: boolean) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no already exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no already exists, update it including the uid
                    const docToUpdate = querySnapshot.docs[0];
                    await updateDoc(doc(db, "web_installer", docToUpdate.id), {
                        artome_product: product,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        lens_setup_completed: lensSetupCompleted,
                        product_setup_completed: productSetupCompleted,
                        re_setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        lens: lens,
                        uid: user.uid // Update the uid
                    });
                } else {
                    // No document with the same artome_serial_no exists, proceed to add
                    await addDoc(collection(db, "web_installer"), {
                        artome_product: product,
                        artome_serial_no: artomeSerNo,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        lens_setup_completed: lensSetupCompleted,
                        product_setup_completed: productSetupCompleted,
                        setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        lens: lens,
                        uid: user.uid
                    });
                }
            } else {
                // User is signed out
                // ...
            }
        });
    } catch (err: any) {
        console.error(err);
    }
};

// Remove the product
const removeProduct = async (artomeSerNo: string) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no exists, delete it
                    const docToDelete = querySnapshot.docs[0];
                    await deleteDoc(doc(db, "web_installer", docToDelete.id));
                } else {
                    console.log("No document found with the specified artome_serial_no");
                }
            } else {
                // User is signed out
                // Handle the case where the user is not authenticated
            }
        });
    } catch (err) {
        console.error(err);
    }
};

// Update user data
const updateUserData = async (field: string, value: string) => {
    const user = auth.currentUser;
    if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            const oldValue = userData[field] || null;

            const updates: { [key: string]: any } = {
                [field]: value,
            };

            if (oldValue) {
                const oldFieldArray = `${field}_history`;
                updates[oldFieldArray] = arrayUnion(oldValue);
            }

            await updateDoc(docRef, updates);
            console.log("User data saved successfully!");
        } else {
            console.log("No such document!");
        }
    } else {
        console.log("No user is signed in.");
    }
};

// Mark user for deletion
const markUserForDeletion = async () => {
    const user = auth.currentUser;
    if (user) {
        try {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // Add a flag to mark the account as "deleted" and a timestamp
                const updates = {
                    markedForDeletion: true,
                    deletionRequestedAt: new Date(),
                };

                await updateDoc(docRef, updates);
                console.log("User account marked for deletion successfully!");
            } else {
                console.log("User document does not exist.");
            }
        } catch (err) {
            console.error("Error marking user for deletion: ", err);
        }
    } else {
        console.log("No user is signed in.");
    }
};

const restoreUserAccount = async () => {
    const user = auth.currentUser;
    if (user) {
        try {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();

                if (userData?.markedForDeletion || userData?.status === "deleted" || userData?.status === "inactive") {
                    // Remove the mark for deletion and set status back to active
                    await updateDoc(docRef, {
                        markedForDeletion: false, // Remove deletion mark
                        deletionRequestedAt: null, // Clear deletion timestamp
                        status: "active", // Set the account status back to active
                    });
                    console.log("User account restored successfully!");
                } else {
                    console.log("User is not marked for deletion or inactive.");
                }
            } else {
                console.log("No user document found.");
            }
        } catch (err) {
            console.error("Error restoring user account: ", err);
        }
    } else {
        console.log("No user is signed in.");
    }
};

const deleteUserAccount = async () => {
    const user = auth.currentUser;
    if (user) {
        try {
            // Step 1: Delete user data from Firestore
            const docRef = doc(db, "users", user.uid);
            await deleteDoc(docRef);
            console.log("User data deleted from Firestore successfully!");

            // Step 2: Delete user from Firebase Authentication
            await user.delete();
            console.log("User account deleted from Firebase Authentication successfully!");
        } catch (err: any) {
            if (err.code === "auth/requires-recent-login") {
                // If the user needs to re-authenticate before deletion
                console.error("Error: User needs to re-authenticate before deletion.");
                // Optionally, you can call reauthenticateUser to prompt for re-authentication
            } else {
                console.error("Error deleting user:", err);
            }
        }
    } else {
        console.log("No user is signed in.");
    }
};

const reauthenticateUser = async (email: string, password: string) => {
    const user = auth.currentUser;
    if (user) {
        const credential = EmailAuthProvider.credential(email, password);
        try {
            await reauthenticateWithCredential(user, credential);
            console.log("User re-authenticated successfully.");
            await deleteUserAccount(); // After re-authentication, delete the account
        } catch (err) {
            console.error("Error during re-authentication:", err);
        }
    }
};

const migrateOldAccounts = async () => {
    try {
        // Query for users where uid is populated but id is empty
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "!=", ""), where("id", "==", ""));

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No old accounts found that need updating.");
            return;
        }

        querySnapshot.forEach(async (docSnapshot) => {
            const userData = docSnapshot.data();

            // Check if uid is populated and id is empty
            if (userData.uid && !userData.id) {
                const updatedData = { id: userData.uid };

                await updateDoc(doc(db, "users", docSnapshot.id), updatedData);
                console.log(`User ${docSnapshot.id} updated: uid copied to id`);
            }
        });
    } catch (error) {
        console.error("Error migrating old accounts:", error);
    }
};

export {
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    emailVerification,
    x20ProvisioningRecords,
    m10ProvisioningRecords,
    s1ProvisioningRecords,
    getUserData,
    getUserProductsData,
    getUserProductItemData,
    removeProduct,
    updateUserData,
    registrationRecords,
    markUserForDeletion,
    restoreUserAccount,
    deleteUserAccount,
    reauthenticateUser,
    migrateOldAccounts,
    migrateUsers,
};